<template>
  <div align="center">
    <br>
    <div class="card col-md-8 rounded shadow-lg" align="left">
      <div class="card-body">
        <div class="card-title">
          <h1>Perfil</h1>
        </div>
        <br>
        <div class="list-group">
          <a href="#" v-on:click="security" class="list-group-item list-group-item-action flex-column align-items-start">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Cambiar contraseña</h5>
              <small></small>
            </div>
            <p class="mb-1">Cambiar la contraseña para iniciar sesión en el sistema.</p>
            <small></small>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //import toastr from 'toastr';
  //feather.replace();

  export default {
    data(){
      return{

      }
    },
    computed: {
      company() {
        return this.$store.state.company
      },
      user() {
        return this.$store.state.user
      }
    },
    created: function() {

    },
    methods: {
      security(){
        this.$router.replace({ name: 'Security' });
      }
    }
  }
  </script>

  <style>
    .warning {
      color: #ff0000;
    }
  </style>
